import styled from 'styled-components'

export const BlogWrapper = styled.div ``;

export const BlogBox = styled.div `
    padding-bottom: 50px;
    margin-bottom: 50px;
    // @media (prefers-color-scheme: light) {
    //     border-bottom: 1px solid ${props => props.theme.colors.borderColor};
    // }
    border-bottom: 1px solid ${props => props.theme.colors.borderColor};
    @media (prefers-color-scheme: dark) {
        border-bottom: 1px solid #121212;
    }
    h3 > a {
        // @media (prefers-color-scheme: light) {
        //     color: #121212;
        // }
        color: #121212;
        @media (prefers-color-scheme: dark) {
            color: #CCCCCC !important;
        }
    }
    // border-bottom: 1px solid ${props => props.theme.colors.borderColor};
`;